import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import CallToAction
                         from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper
                         from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Carousel          from '@interness/web-core/src/components/media/Carousel/Carousel';
import Display           from '@interness/theme-default/src/components/Display/Display';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';

function IndexPage(props) {
  return (
    <>
      <Carousel>
        {props.data.headerImages.edges.map((image) => (
          <Img key={image.node.name} fluid={image.node.childImageSharp.fluid} alt={image.node.name}/>
        ))}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading subtitle={'Herzlich willkommen'}>Juwelier Koll</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Willkommen bei Juwelier Koll, Ihrem traditionsreichen Juweliergeschäft im malerischen Remscheid Lennep. Unsere Meisterwerkstatt, geführt von einem erfahrenen Uhrmachermeister, bietet Ihnen eine exklusive Auswahl an Uhren, Schmuck, Trauringen, Eheringen und Antragsringen, die mit Sorgfalt und handwerklicher Präzision gefertigt werden.</p>
            <p>Unsere Expertise als Uhrmachermeister ermöglicht es uns, eine breite Palette an hochwertigen Uhren anzubieten, die sowohl zeitlose Eleganz als auch modernes Design vereinen. Neben unseren Uhrenkollektionen präsentieren wir Ihnen eine erlesene Auswahl an Schmuckstücken, die jeden Anlass besonders machen. Ob es um die Wahl des perfekten Antragsrings geht, die Gestaltung individueller Eheringe oder das Finden eines einzigartigen Schmuckstücks – bei Juwelier Koll finden Sie Qualität und Schönheit in jeder Facette.</p>
            <p>Wir legen großen Wert auf persönlichen Service und Kundenzufriedenheit. Unsere qualifizierten Mitarbeiter stehen Ihnen mit fachkundiger Beratung zur Seite und helfen Ihnen, das perfekte Stück für Ihre besonderen Momente zu finden. In unserer Meisterwerkstatt bieten wir außerdem umfangreiche Reparatur- und Wartungsdienste an, um die Langlebigkeit und Schönheit Ihrer Schmuckstücke und Uhren zu gewährleisten.</p>
            <p>Bei Juwelier Koll in Remscheid Lennep bieten wir auch einen Altgoldankauf-Service, mit dem Sie alte Schätze in neue Kreationen verwandeln können.</p>
            <p>Unser Ziel ist es, Ihnen ein unvergessliches Einkaufserlebnis zu bieten, bei dem Ihre Wünsche und Bedürfnisse im Mittelpunkt stehen. Wir laden Sie herzlich ein, unsere Welt der feinen Uhren und des exquisiten Schmucks zu entdecken. Besuchen Sie uns und lassen Sie sich von der Vielfalt und Qualität unserer Kollektionen inspirieren.</p>
            <p>Bis bald in Lennep, Ihr Stefan Koll mit Team</p>
          </span>
        </section>
        <LiveAnnouncements/>
        <Spacer/>
        <section>
          <Heading tag={'h2'}>Unsere Markenwelt</Heading>
          <Display/>
          <Spacer/>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query IndexQuery {
        headerImages: allFile(filter: {relativeDirectory: {eq: "headers/index"}}) {
            edges {
                node {
                    ...HeaderImages
                }
            }
        }
    }
`;